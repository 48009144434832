import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useSpring, animated } from 'react-spring';
import { BsExclamationCircleFill, BsCheckCircleFill, BsInfoCircleFill, BsXCircleFill } from 'react-icons/bs';

const ModalAviso = ({ tipo, titulo, mensaje, onClose }) => {
    const [open, setOpen] = useState(true); // Inicia abierto por defecto

    const handleClose = () => {
        setOpen(false);
        onClose(); // Llama a la función onClose del padre para cerrar el modal principal
    };

    // Definir animaciones para el modal y el contenido
    const modalProps = useSpring({
        opacity: open ? 1 : 0,
        transform: open ? 'translateY(0)' : 'translateY(-50px)',
        config: { duration: 250 },
    });

    // Animaciones para los iconos
    const iconProps = useSpring({
        from: { opacity: 0, transform: 'scale(0.8)' },
        to: { opacity: open ? 1 : 0, transform: open ? 'scale(1)' : 'scale(0.8)' },
        config: { tension: 300, friction: 20 },
    });

    let icono = null;
    let iconoComponent = null;
    let alertVariant = '';
    switch (tipo) {
        case 'error':
            alertVariant = 'danger';
            icono = <BsXCircleFill style={{ fontSize: '2rem', color: '#dc3545', marginBottom: '0.25rem' }} />;
            break;
        case 'alerta':
            alertVariant = 'warning';
            icono = <BsExclamationCircleFill style={{ fontSize: '2rem', color: '#ffc107', marginBottom: '0.25rem' }} />;
            break;
        case 'exito':
            alertVariant = 'success';
            icono = <BsCheckCircleFill style={{ fontSize: '2rem', color: '#198754', marginBottom: '0.25rem' }} />;
            break;
        case 'validacion':
            alertVariant = 'info';
            icono = <BsInfoCircleFill style={{ fontSize: '2rem', color: '#0dcaf0', marginBottom: '0.25rem' }} />;
            break;
        default:
            alertVariant = 'primary';
            break;
    }

    iconoComponent = (
        <animated.div style={iconProps}>
            {icono}
        </animated.div>
    );

    return (
        <Modal show={open} onHide={handleClose} centered>
            <animated.div style={modalProps}>
                <Modal.Header closeButton>
                    {iconoComponent}
                    <Modal.Title style={{ display: 'inline-block', marginLeft: '0.5rem' }}>{titulo}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{mensaje}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={alertVariant} onClick={handleClose}>
                        Entendido
                    </Button>
                </Modal.Footer>
            </animated.div>
        </Modal>
    );
};

export default ModalAviso;
