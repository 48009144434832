import React, { useState, useEffect } from 'react';
import { Modal, Form, Col, Row, Button } from 'react-bootstrap';
import { TextField, MenuItem } from '@mui/material';
import AvatarEditor from 'react-avatar-editor';

const EditarUsuario = ({ data }) => {
    
    const [nombre, setNombre] = useState(data.nombre);
    const [usuario, setUsuario] = useState(data.usuario);
    const [apellido, setApellido] = useState(data.apellido);
    const [rut, setRut] = useState(data.rut);
    const [celular, setCelular] = useState(data.celular);
    const [email, setEmail] = useState(data.email);
    const [rol, setRol] = useState(data.rol);
    const [licencia, setLicencia] = useState('');

    const [selectedImage, setSelectedImage] = useState(null);

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedImage(file);
        }
    };

    const enviarDatosALaAPI = () => {
        // Aquí deberías implementar la lógica para enviar datos a la API
        // Puedes usar axios u otra librería para realizar la solicitud HTTP
        // Por ahora, simplemente mostramos los datos en la consola
        console.log({
            nombre,
            apellido,
            rut,
            celular,
            email,
            rol,
            licencia,
            selectedImage,
        });

        // Cierra el modal después de guardar los datos
        handleClose();
    };
    

    return (
        <div>
            <Button
                variant="primary"  // Puedes cambiar el color según tus necesidades
                title="Editar"
                size="sm"
                style={{ marginRight: '5px' }}
                onClick={handleOpen}
            >
                <i className="material-icons">&#xE254;</i>
            </Button>
            <Modal show={open} onHide={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Editar Usuario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <Row className="mb-3">
                                <Col>
                                    <TextField
                                        className='input-text'
                                        id="LabelModalNombre"
                                        label="Nombre"
                                        variant="outlined"
                                        fullWidth
                                        value={nombre}
                                        onChange={(e) => setNombre(e.target.value)}
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        className='input-text'
                                        id="LabelModalApellido"
                                        label="Apellido"
                                        variant="outlined"
                                        fullWidth
                                        value={apellido}
                                        onChange={(e) => setApellido(e.target.value)}
                                    />
                                </Col>
                            </Row>

                            <TextField
                                className='input-text'
                                id="LabelModalUsuario"
                                label="Usuario"
                                variant="outlined"
                                fullWidth
                                style={{ marginBottom: '16px' }}
                                value={usuario}
                                onChange={(e) => setUsuario(e.target.value)}
                            />

                            <TextField
                                className='input-text'
                                id="LabelModalRut"
                                label="RUT"
                                variant="outlined"
                                fullWidth
                                style={{ marginBottom: '16px' }}
                                value={rut}
                                onChange={(e) => setRut(e.target.value)}
                            />

                            <Row className="mb-3">
                                <Col>
                                    <TextField
                                        className='input-text'
                                        id="LabelModalCelular"
                                        label="Celular (Chileno)"
                                        variant="outlined"
                                        fullWidth
                                        type="tel"
                                        inputProps={{ pattern: "[0-9]{9}" }}
                                        value={celular}
                                        onChange={(e) => setCelular(e.target.value)}
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        className='input-text'
                                        id="LabelModalEmail"
                                        label="Email"
                                        variant="outlined"
                                        fullWidth
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Col>
                            </Row>

                            <TextField
                                className='input-text'
                                id="LabelModalRol"
                                label="Rol"
                                variant="outlined"
                                fullWidth
                                select
                                style={{ marginBottom: '16px' }}
                                value={rol}
                                onChange={(e) => setRol(e.target.value)}
                            >
                                <MenuItem value="">Seleccione un rol</MenuItem>
                                <MenuItem value="admin">Administrador</MenuItem>
                                <MenuItem value="user">Usuario</MenuItem>
                                <MenuItem value="Op">Op</MenuItem>
                            </TextField>

                            <TextField
                                className='input-text'
                                id="LabelModalLicencia"
                                label="Licencia"
                                variant="outlined"
                                fullWidth
                                style={{ marginBottom: '16px' }}
                                value={licencia}
                                onChange={(e) => setLicencia(e.target.value)}
                            />
                        </Col>

                        <Col>
                            {selectedImage && (
                                <div style={{ textAlign: 'center', marginBottom: '16px' }}>
                                    <AvatarEditor

                                        image={selectedImage}
                                        width={350}
                                        height={350}
                                        border={0}  // Configuración para que no haya bordes blancos
                                        borderRadius={200}
                                        scale={1}
                                    />
                                </div>
                            )}
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                style={{ marginBottom: '16px' }}
                            />
                            <Button variant="primary" onClick={enviarDatosALaAPI} style={{ textAlign: 'right' }}>
                                Guardar usuario
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
        </div>
    );
};

export default EditarUsuario;
