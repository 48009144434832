// TablaDestinos.jsx
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import CardDestino from './CardDestino';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AgregarDestino from './AgregarDestino';
import ModalAviso from '../otros/ModalAvisos'; // Ajusta la importación según la ubicación de ModalAviso

const TablaDestinos = () => {
    const [destinos, setDestinos] = useState([]);
    const [tipoMensaje, setTipoMensaje] = useState(null);

    useEffect(() => {
        obtenerDestinos();
    }, []);

    // Función para obtener los destinos desde la API
    const obtenerDestinos = async () => {
        try {
            const response = await fetch('https://cronos.edgarleal.es/token/api/web/Destinos/TablaDestinos.php');
            if (response.ok) {
                const data = await response.json();
                setDestinos(data);
            } else {
                console.error('Error al obtener los destinos');
            }
        } catch (error) {
            console.error('Error al obtener los destinos:', error);
        }
    };

    // Función para actualizar la tabla de destinos
    const actualizarTabla = () => {
        obtenerDestinos();
    };

    return (
        <div className="container ">
            <div className="crud shadow-lg p-3 mb-5 mt-5 bg-body rounded">
                <div className="row">
                    <div className="col-sm-3 mt-5 mb-4 text-gred">
                        <div className="search">
                            <form className="form-inline">
                                <input className="form-control mr-sm-2" type="search" placeholder="Buscar" aria-label="Search" />
                            </form>
                        </div>
                    </div>
                    <div className="col-sm-3 offset-sm-2 mt-5 mb-4 text-gred" style={{ color: "red" }}>
                        <h2><b>Destinos</b></h2>
                    </div>
                    <div className="col-sm-3 offset-sm-1  mt-5 mb-4 text-gred">
                        <AgregarDestino actualizarTabla={actualizarTabla} />
                    </div>
                </div>
                <div className="row">
                    <div className="table-responsive">
                        <div className="crud p-3 mb-5 mt-5 bg-body rounded">
                            <Row xs={1} md={2} className="g-4">
                                {destinos.map((destino, index) => (
                                    <Col key={index}>
                                        <CardDestino destino={destino} />
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </div>
                </div>
            </div>

            {/* ModalAviso para mostrar mensajes de éxito o error */}
            {tipoMensaje && (
                <ModalAviso
                    tipo={tipoMensaje}
                    titulo={tipoMensaje === 'exito' ? 'Éxito' : 'Error'}
                    mensaje={tipoMensaje === 'exito' ? 'El destino se agregó correctamente.' : 'Hubo un error al agregar el destino.'}
                    onClose={() => setTipoMensaje(null)} // Cierra el modal de aviso y resetea el tipo de mensaje
                />
            )}
        </div>
    );
};

export default TablaDestinos;
