// CardUsuarios.jsx
import React from "react";
import { Card } from "react-bootstrap";

const CardUsuarios = ({ usuario }) => {
    return (
        <Card
            style={{
                width: '18rem',
                marginBottom: '10px',
                border: usuario.borderColor ? `2px solid ${usuario.borderColor}` : '3px solid black',
                borderTopLeftRadius: '60px',
                borderBottomLeftRadius: '60px',
                overflow: 'hidden'
            }}
        >
            <Card.Body style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '60px', height: '60px', borderRadius: '50%', overflow: 'hidden' }}>
                    <img
                        src={usuario.fotoperfil}
                        alt={usuario.usuario}
                        style={{ width: '100%', height: '100%', borderRadius: '50%' }}
                    />
                </div>
                <div style={{ marginLeft: '10px' }}>
                    <Card.Title>{usuario.usuario}</Card.Title>
                    <Card.Text>{usuario.email}</Card.Text>
                </div>
            </Card.Body>
        </Card>
    );
};

export default CardUsuarios;
