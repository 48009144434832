import React, { useState } from 'react';
import { Modal, Form, Col, Row, Button, Carousel } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import BuscadorDirecciones from './BuscadorDirecciones';
import ModalAviso from '../otros/ModalAvisos'; // Ajusta la importación según la ubicación de ModalAviso

const AgregarDestino = ({ actualizarTabla }) => {
    const [nombreDestino, setNombreDestino] = useState('');
    const [contacto, setContacto] = useState('');
    const [telefono, setTelefono] = useState('');
    const [direccion, setDireccion] = useState('');
    const [images, setImages] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [tipoMensaje, setTipoMensaje] = useState(null);
    const [mensajeError, setMensajeError] = useState('');

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setTipoMensaje(null); // Reinicia el mensaje de tipo
        setMensajeError(''); // Reinicia el mensaje de error
    };

    const handleImageSelect = (selectedImages) => {
        setImages(selectedImages);
    };

    const handleDireccionSelect = (direccion) => {
        setDireccion(direccion);
    };

    const handleSelectPlace = (placeData) => {
        setSelectedPlace(placeData);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Verifica si selectedPlace es null
        if (!selectedPlace || !selectedPlace.geometry || !selectedPlace.geometry.location) {
            console.error('No se ha seleccionado un lugar válido');
            return;
        }

        // Obtén los datos del usuario del localStorage
        const userData = JSON.parse(localStorage.getItem('userData'));

        if (!userData || !userData.empresa_idEmpresa) {
            console.error('ID de empresa no encontrado en los datos del usuario en el localStorage');
            return;
        }

        const idEmpresa = userData.empresa_idEmpresa;

        const formData = new FormData();
        formData.append('imagen', images[0]); // Solo enviando la primera imagen por ahora
        formData.append('direccion', direccion);

        // Accede a las coordenadas desde selectedPlace.geometry.location
        formData.append('latitud', selectedPlace.geometry.location.lat());
        formData.append('longitud', selectedPlace.geometry.location.lng());

        formData.append('contacto', contacto);
        formData.append('telefono', telefono);
        formData.append('punto', nombreDestino);
        formData.append('id_empresa', idEmpresa); // Utiliza el id de la empresa obtenido del localStorage

        try {
            const response = await fetch('https://cronos.edgarleal.es/token/api/web/Destinos/AgregarDestino.php', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Error al enviar el destino');
            }

            const data = await response.json();
            console.log('Respuesta de la API:', data);

            if (data.status === 'success') {
                // Cierra el modal principal primero
                handleClose();

                // Mostrar mensaje de éxito
                setTipoMensaje('exito');

                // Actualiza la tabla de destinos en TablaDestinos
                actualizarTabla();
            } else {
                // Muestra el mensaje de error en el modal
                setMensajeError(data.message);
                setTipoMensaje('error');
            }

        } catch (error) {
            console.error('Error al enviar la solicitud:', error);
            // Mostrar mensaje de error en el modal
            setMensajeError('Hubo un error al agregar el destino.');
            setTipoMensaje('error');
        }
    };

    return (
        <>
            <Button
                variant="primary"
                size="sm"
                style={{ marginRight: '5px' }}
                onClick={handleOpen}
            >
                Nuevo Destino
            </Button>

            <Modal show={open} onHide={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Agregar Destino</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col>
                                <BuscadorDirecciones
                                    onImageSelect={handleImageSelect}
                                    onDireccionSelect={handleDireccionSelect}
                                    onSelectPlace={handleSelectPlace}
                                />

                            </Col>
                            <Col>
                                <TextField
                                    className='input-text'
                                    label="Nombre del destino"
                                    variant="outlined"
                                    fullWidth
                                    value={nombreDestino}
                                    onChange={(e) => setNombreDestino(e.target.value)}
                                />

                                <TextField
                                    className='input-text'
                                    label="Contacto"
                                    variant="outlined"
                                    fullWidth
                                    style={{ marginTop: '16px' }}
                                    value={contacto}
                                    onChange={(e) => setContacto(e.target.value)}
                                />

                                <TextField
                                    className='input-text'
                                    label="Teléfono"
                                    variant="outlined"
                                    fullWidth
                                    style={{ marginTop: '16px' }}
                                    value={telefono}
                                    onChange={(e) => setTelefono(e.target.value)}
                                />

                                <div style={{ width: '400px', height: '250px', marginTop: '16px', overflow: 'hidden' }}>
                                    <Carousel style={{ width: '100%', height: '100%' }}>
                                        {images.map((image, index) => (
                                            <Carousel.Item key={index}>
                                                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <img
                                                        src={image}
                                                        alt={`Image ${index}`}
                                                        style={{ maxHeight: '250px', maxWidth: '400px', objectFit: 'contain', margin: 'auto' }}
                                                    />
                                                </div>
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>
                                </div>
                                <Button type="submit" variant="success" style={{ marginTop: '16px', textAlign: 'right' }}>
                                    Agregar Destino
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* ModalAviso para mostrar mensajes de éxito o error */}
            {tipoMensaje && (
                <ModalAviso
                    tipo={tipoMensaje}
                    titulo={tipoMensaje === 'exito' ? 'Éxito' : 'Error'}
                    mensaje={tipoMensaje === 'exito' ? 'El destino se agregó correctamente.' : mensajeError}
                    onClose={() => setTipoMensaje(null)} // Cierra el modal de aviso y resetea el tipo de mensaje
                />
            )}
        </>
    );
};

export default AgregarDestino;
