import React, { useState } from 'react';
import { Modal, Col, Row, Button, Image } from 'react-bootstrap';
import { TextField, MenuItem } from '@mui/material';

const VerUsuario = ({ usuario }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button
                variant="success"
                title="Ver"
                size="sm"
                style={{ marginRight: '5px' }}
                onClick={handleOpen}
            >
                <i className="material-icons">&#xE417;</i>
            </Button>
            <Modal show={open} onHide={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Modal.Header closeButton>
                    <Modal.Title>Ver Usuario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center">
                        <Image src={usuario.fotoperfil} alt="Foto de perfil" fluid style={{ width: '150px', height: '150px', borderRadius: '50%', marginBottom: '10px' }} />
                    </div>
                    <Row style={{ marginBottom: '10px' }} >
                        <Col>
                            <TextField label="Nombre" variant="outlined" fullWidth value={usuario.nombre} disabled />
                        </Col>
                        <Col>
                            <TextField label="Apellido" variant="outlined" fullWidth value={usuario.apellido} disabled />
                        </Col>
                    </Row>
                    <TextField label="Usuario" variant="outlined" fullWidth value={usuario.usuario} disabled style={{ marginBottom: '10px' }} />
                    <Row style={{ marginBottom: '10px' }}> 
                        <Col>
                    <TextField label="RUT" variant="outlined" fullWidth value={usuario.rut} disabled />
                        </Col>
                        <Col>
                    <TextField label="Celular" variant="outlined" fullWidth value={usuario.celular} disabled />
                        </Col>
                    </Row>
                    <TextField label="Email" variant="outlined" fullWidth value={usuario.email} disabled style={{ marginBottom: '10px' }} />
                    <TextField label="Rol" variant="outlined" fullWidth value={usuario.rol_idRol} disabled style={{ marginBottom: '10px' }} />
                    <TextField label="Licencia" variant="outlined" fullWidth value={usuario.token_idToken} disabled />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default VerUsuario;
