import { Box, Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, Stack, TextField, Typography, circularProgressClasses, colors } from "@mui/material";
import React, { useState } from "react";

const ConfiguracionPage = () => {

    return (
        <Box
            position="relative"
            height="100vh"
            sx={{ "::-webkit-scrollbar": { display: "none" } }}
        >
            <Typography color="error" fontWeight="bold" fontSize={40}>Pagina Configuracion</Typography>
        </Box>
    )
}
export default ConfiguracionPage;