import React from 'react';
import { Table } from 'react-bootstrap';

const TablaDuraciones = ({ duraciones }) => {
    return (
        <div style={{ marginTop: '20px', width: '100%' }}>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Desde</th>
                        <th>Hasta</th>
                        <th>Duración</th>
                        <th>llegada</th>
                    </tr>
                </thead>
                <tbody>
                    {duraciones.map((duracion, index) => (
                        <tr key={index}>
                            <td style={{ maxWidth: "500px" }}>{duracion.desde}</td>
                            <td style={{ maxWidth: "500px" }}>{duracion.hasta}</td>
                            <td style={{ width: "100px" }}>{duracion.duration}</td>
                            <td style={{ width: "100px" }}>{duracion.horaEstimada}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default TablaDuraciones;
