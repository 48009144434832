import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from 'react';
import { Button, Input } from 'react-bootstrap';
import AgregarUsuario from "./AgregarUsuario";
import VerUsuario from "./VerUsuario";
import EditarUsuario from "./EditarUsuario";

const TablaUsuarios = () => {
    const [showNuevoUsuario, setShowNuevoUsuario] = useState(false);
    const [usuarios, setUsuarios] = useState([]);
    const handleCloseModal = () => setShowNuevoUsuario(false);
    const handleShow = () => setShowNuevoUsuario(true);


    const fetchData = async () => {
        try {
            // Realizar la llamada a la API y obtener los datos
            const response = await fetch('https://cronos.edgarleal.es/token/api/web/Usuarios/TablaUsuarios.php');
            const data = await response.json();
            // Actualizar el estado con los datos obtenidos
            setUsuarios(data);
        } catch (error) {
            console.error('Error fetching data: ', error);
        }
    };

    const handleDeleteClick = async (userId) => {
        try {
            const response = await fetch('https://cronos.edgarleal.es/token/api/web/Usuarios/EliminarUsuario.php', {
                method: 'PATCH', // Cambiar a PATCH
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: userId,
                }),
            });

            if (response.ok) {
                fetchData(); // Actualiza la lista de usuarios después de la "eliminación"
                console.log(`Usuario con ID ${userId} marcado como eliminado correctamente.`);
            } else {
                const errorText = await response.text();
                console.error(`Error al marcar como eliminado el usuario con ID ${userId}.`, response.statusText, errorText);
            }
        } catch (error) {
            console.error('Error en la solicitud PATCH: ', error);
        }
    };

    const   handleViewClick = (userId) => {
        // Lógica para el botón de ver (puedes agregar tu propia lógica aquí)
        console.log(`Ver detalles del usuario con ID ${userId}`);
    };

    const handleEditClick = (userId) => {
        // Lógica para el botón de editar (puedes agregar tu propia lógica aquí)
        console.log(`Editar usuario con ID ${userId}`);
    };

    // Llamar a fetchData al cargar el componente
    useEffect(() => {
        fetchData();
    }, []);

    return (

        <div class="container ">
            <div className="crud shadow-lg p-3 mb-5 mt-5 bg-body rounded">
                <div class="row ">

                    <div class="col-sm-3 mt-5 mb-4 text-gred">
                        <div className="search">
                            <form class="form-inline">
                                <input class="form-control mr-sm-2" type="search" placeholder="Buscar" aria-label="Search" />

                            </form>
                        </div>
                    </div>
                    <div class="col-sm-3 offset-sm-2 mt-5 mb-4 text-gred" style={{ color: "red" }}><h2><b>Usuarios</b></h2></div>
                    <div class="col-sm-3 offset-sm-1  mt-5 mb-4 text-gred">
                        <AgregarUsuario/>
                    </div>
                </div>
                <div class="row">
                    <div class="table-responsive " >
                        <table className="table table-striped table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th style={{ width: "50px" }}>Foto</th>
                                    <th>Nombre</th>
                                    <th>Usuario</th>
                                    <th>Rut</th>
                                    <th>Rol</th>
                                    <th>Estado</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* Mapear los datos de usuarios y renderizar filas */}
                                {usuarios.map((usuario, index) => (
                                    <tr key={index}>
                                        <td><img src={usuario.fotoperfil} alt="Foto de perfil" style={{ width: "50px", height: "50px" }} /></td>
                                        <td>{usuario.nombre}</td>
                                        <td>{usuario.usuario}</td>
                                        <td>{usuario.rut}</td>
                                        <td>{usuario.rol_idRol}</td>
                                        <td>{usuario.token_idToken}</td>
                                        <td className="d-flex align-items-center">
                                            <VerUsuario usuario={usuario}/>
                                            <EditarUsuario data={usuario}/>
                                            <Button
                                                variant="danger"
                                                title="Eliminar"
                                                size="sm"
                                                onClick={() => handleDeleteClick(usuario.idUsuario)}
                                            >
                                                <i className="material-icons">&#xE872;</i>
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default TablaUsuarios;