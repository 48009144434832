import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

const CardDestino = ({ destino }) => {
    const apiKey = "AIzaSyCxK9DUpBomKCM1gkSuAmiF3I0bu2WWm5k";
    const direccion = destino.direccion;
    const direccionCodificada = encodeURIComponent(direccion);
    const streetViewUrl = `https://maps.googleapis.com/maps/api/streetview?size=600x400&location=${direccionCodificada}&key=${apiKey}`;

    return (
        <Card className='shadow' style={{ width: '100%', height: '100%' }}>
            <Row>
                <Col xs={3} style={{ padding: '0', margin: '0' }}>
                    <div style={{
                        width: '100%',
                        height: '100%',
                        background: `url('${streetViewUrl}') no-repeat center center`,
                        backgroundSize: 'cover',
                    }}>
                    </div>
                </Col>
                <Col xs={9} style={{ padding: '0', margin: '0' }}>
                    <Card.Body style={{ fontSize: '1em', height: '100%', overflow: 'hidden', margin: '0', width: '100%' }}>
                        <Card.Title style={{ fontSize: '1.2em', fontWeight: 'bold', marginBottom: '0.3em', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                            {destino.punto}
                        </Card.Title>
                        <Card.Text style={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '0.9em' }}>
                            <p style={{ margin: '0', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                Dirección: {destino.direccion}
                            </p>
                            <p style={{ margin: '0', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                Contacto: {destino.contacto} 
                            </p>
                            <p style={{ margin: '0', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                Telefono: {destino.telefono}
                            </p>
                        </Card.Text>
                    </Card.Body>
                </Col>
            </Row>
        </Card>
    );
};

export default CardDestino;
