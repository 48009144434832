import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ component: Component, rolesPermitidos }) => {
  const token = localStorage.getItem('token');
  const sessionStart = localStorage.getItem('sessionStart');
  const userData = JSON.parse(localStorage.getItem('userData'));
  const userRole = userData?.rol_idRol;

  if (token && sessionStart && userRole) {
    const now = Date.now();
    const sessionDuration = 60 * 60 * 1000; // 10 minuto en milisegundos

    if (now - sessionStart > sessionDuration) {
      // Si la sesión ha expirado, removemos el token y redirigimos al login
      localStorage.removeItem('token');
      localStorage.removeItem('sessionStart');
      return <Navigate to="/" />;
    }

    if (rolesPermitidos.includes(userRole)) {
      return <Component />;
    } else {
      // El usuario no tiene permisos para acceder a esta ruta
      return <Navigate to="/no-autorizado" />;
    }
  } else {
    // El usuario no ha iniciado sesión, lo redirigimos al login
    return <Navigate to="/" />;
  }
};

export default PrivateRoute;
