import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const NoAutorizadoPage = () => {
    return (
        <Box sx={{ textAlign: 'center', mt: 5 }}>
            <Typography variant="h4" color="error">No tienes permiso para acceder a esta página.</Typography>
            <Typography variant="h6">
                Regresa a <Link to="/inicio">Inicio</Link>
            </Typography>
        </Box>
    );
};

export default NoAutorizadoPage;
