import React, { useEffect } from 'react';
import { GoogleMap, LoadScript, TrafficLayer } from '@react-google-maps/api';
import mapStyles from '../../assets/estilos/mapStyles'

const MostrarMapa = () => {
  useEffect(() => {
    console.log('El componente MostrarMapa se está utilizando.');
  }, []);
    const mapTamano = {
        height: '100%',
        width: '100%'
    };

  const mapOptions = {
    streetViewControl: false, // Desactiva el control de Street View
    styles: [
      ...mapStyles,
      {
        featureType: 'poi',
        elementType: 'labels',
        
        stylers: [
          { visibility: 'off' } // Oculta las etiquetas de los puntos de interés (marcadores predeterminados)
        ]
      }
    ]
  };

    const defaultCenter = { lat: -33.4489, lng: -70.6693 }; // Centro de Santiago

    const marcadores = []; // No hay marcadores por defecto, se podrían añadir aquí si fuera necesario

    return (
        <LoadScript googleMapsApiKey="AIzaSyCxK9DUpBomKCM1gkSuAmiF3I0bu2WWm5k">
            <GoogleMap
                mapContainerStyle={mapTamano}
                zoom={11}
                center={defaultCenter}
                options={mapOptions}
            >
          <TrafficLayer autoUpdate />
            </GoogleMap>
        </LoadScript>
    );
};

export default MostrarMapa;