import React, { useState, useEffect } from 'react';
import { Modal, Form, Col, Row, Button } from 'react-bootstrap';
import axios from 'axios';
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from 'react-icons/md';
import { HiMiniBars3 } from 'react-icons/hi2';
import MapaRuta from './MapaRuta';
import TablaDuraciones from './TablaDuraciones';
import { Select, MenuItem, FormControl, InputLabel, Avatar } from '@mui/material';

const AgregarRuta = () => {
    const [open, setOpen] = useState(false);
    const [choferSeleccionado, setChoferSeleccionado] = useState('');
    const [horarioSeleccionado, setHorarioSeleccionado] = useState('');
    const [puntosRuta, setPuntosRuta] = useState([]);
    const [puntosDisponibles, setPuntosDisponibles] = useState([]);
    const [choferes, setChoferes] = useState([]);
    const [horarios, setHorarios] = useState([]);
    const [duraciones, setDuraciones] = useState([]);
    const [draggedIndex, setDraggedIndex] = useState(null);
    const [searchPuntos, setSearchPuntos] = useState('');
    const [searchRuta, setSearchRuta] = useState('');
    const empresa_idEmpresa = JSON.parse(localStorage.getItem('userData')).empresa_idEmpresa;

    useEffect(() => {
        const fetchChoferes = async () => {
            try {
                const response = await axios.post('https://cronos.edgarleal.es/token/api/web/Rutas/ObtenerChofer.php', {
                    empresa_idEmpresa: empresa_idEmpresa
                });
                setChoferes(response.data);
            } catch (error) {
                console.error('Error fetching choferes: ', error);
            }
        };

        if (empresa_idEmpresa !== null) {
            fetchChoferes();
        }
    }, [empresa_idEmpresa]);

    useEffect(() => {
        const fetchHorarios = async () => {
            try {

                // Verificar que empresa_idEmpresa no sea null o undefined
                if (empresa_idEmpresa !== null && empresa_idEmpresa !== undefined) {
                    // Preparar los datos a enviar
                    const dataToSend = {
                        empresa_idEmpresa: empresa_idEmpresa
                    };

                    // Mostrar en consola el JSON convertido a cadena
                    console.log('JSON enviado:', JSON.stringify(dataToSend));

                    // Enviar solicitud POST a la API
                    const response = await axios.post('https://cronos.edgarleal.es/token/api/web/Rutas/ObtenerHorarios.php', dataToSend);

                    // Actualizar el estado con los datos recibidos
                    setHorarios(response.data);
                } else {
                    console.error('Error: empresa_idEmpresa es null o undefined.');
                }
            } catch (error) {
                console.error('Error fetching horarios: ', error);
            }
        };

        // Llamar fetchHorarios solo si empresa_idEmpresa cambia
        fetchHorarios();
    }, [empresa_idEmpresa]);


    useEffect(() => {
        const fetchPuntosDisponibles = async () => {
            try {
                const response = await axios.get('https://cronos.edgarleal.es/token/api/web/Destinos/TablaDestinos.php');
                setPuntosDisponibles(response.data);
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };
        fetchPuntosDisponibles();
    }, []);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChoferChange = (event) => {
        setChoferSeleccionado(event.target.value);
    };

    const handleHorarioChange = (event) => {
        setHorarioSeleccionado(event.target.value);
    };

    const agregarPuntoRuta = (punto) => {
        setPuntosRuta([...puntosRuta, punto]);
        const puntosDisponiblesActualizados = puntosDisponibles.filter((p) => p.idPunto !== punto.idPunto);
        setPuntosDisponibles(puntosDisponiblesActualizados);
    };

    const eliminarPuntoRuta = (index) => {
        const puntoEliminado = puntosRuta[index];
        const puntosRutaActualizados = puntosRuta.filter((p, i) => i !== index);
        setPuntosRuta(puntosRutaActualizados);
        setPuntosDisponibles([...puntosDisponibles, puntoEliminado]);
    };

    const handleDragStart = (index) => {
        setDraggedIndex(index);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (index) => {
        const draggedItem = puntosRuta[draggedIndex];
        const remainingItems = puntosRuta.filter((_, i) => i !== draggedIndex);
        const newItems = [
            ...remainingItems.slice(0, index),
            draggedItem,
            ...remainingItems.slice(index)
        ];
        setPuntosRuta(newItems);
        setDraggedIndex(null);
    };

    const handleDragEnd = () => {
        setDraggedIndex(null);
    };

    const handleSearchPuntosChange = (event) => {
        setSearchPuntos(event.target.value);
    };

    const handleSearchRutaChange = (event) => {
        setSearchRuta(event.target.value);
    };

    const filteredPuntosDisponibles = puntosDisponibles.filter((punto) =>
        punto.punto.toLowerCase().includes(searchPuntos.toLowerCase())
    );

    const filteredPuntosRuta = puntosRuta.filter((punto) =>
        punto.punto.toLowerCase().includes(searchRuta.toLowerCase())
    );

    const handleSubmit = async () => {
        const rutaData = {
            chofer: choferSeleccionado,
            horario: horarioSeleccionado,
            puntos: puntosRuta,
            
        };

        try {
            const response = await axios.post('https://tu-api-url.com/rutas', rutaData);
            console.log('Ruta creada con éxito:', response.data);
            handleClose();
        } catch (error) {
            console.error('Error creando la ruta:', error);
        }
    };

    return (
        <>
            <Button
                variant="primary"
                size="sm"
                style={{ marginRight: '5px' }}
                onClick={handleOpen}
            >
                Nueva Ruta
            </Button>

            <Modal show={open} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Agregar Nueva Ruta</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col xs={6} md={4}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>Seleccione al chofer</InputLabel>
                                    <Select
                                        value={choferSeleccionado}
                                        onChange={handleChoferChange}
                                        label="Seleccione al chofer"
                                        renderValue={(selected) => {
                                            const chofer = choferes.find(c => c.idUsuario === selected);
                                            return (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Avatar src={chofer.fotoperfil} style={{ marginRight: '10px', width: '26px', height: '26px' }} />
                                                    <span>{chofer.nombre} {chofer.apellido}</span>
                                                </div>
                                            );
                                        }}
                                        style={{ display: 'flex', alignItems: 'center' }}
                                    >
                                        {choferes.map((chofer) => (
                                            <MenuItem key={chofer.idUsuario} value={chofer.idUsuario} style={{ display: 'flex', alignItems: 'center' }}>
                                                <Avatar src={chofer.fotoperfil} style={{ marginRight: '10px', width: '30px', height: '30px' }} />
                                                <span>{chofer.nombre} {chofer.apellido}</span>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Col>

                            <Col xs={6} md={3}>
                                <FormControl fullWidth variant="outlined" style={{ marginBottom: '1rem' }}>
                                    <InputLabel>Seleccionar Horario</InputLabel>
                                    <Select value={horarioSeleccionado} onChange={handleHorarioChange} label="Seleccionar Horario">
                                        {horarios.map((horario) => (
                                            <MenuItem key={horario.idHorarios} value={horario.idHorarios}>
                                                {horario.horario}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Col>
                        </Row>

                        <hr />

                        <Row>
                            <Col xs={12} md={3} className="mb-3">
                                <Row className="align-items-center" style={{ height: '40px' }}>
                                    <Col xs={3} md={3} className="mb-3">
                                        <h5>Puntos</h5>
                                    </Col>
                                    <Col xs={7} md={7} className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Buscar punto..."
                                            value={searchPuntos}
                                            onChange={handleSearchPuntosChange}
                                        />
                                    </Col>
                                    <Col xs={1} md={1} className="mb-3">
                                        <Button variant="success" size="sm">+</Button>
                                    </Col>
                                </Row>
                                <ul style={{ padding: 0, margin: 0, maxHeight: '350px', overflowY: 'scroll' }}>
                                    {filteredPuntosDisponibles.map((punto, index) => (
                                        <li key={punto.idPunto} style={{ marginBottom: '8px', listStyle: 'none', padding: 0 }}>
                                            <div style={{ backgroundColor: '#f0f0f0', padding: '8px', borderRadius: '5px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <span style={{ fontSize: '15px', flex: 1 }}>{punto.punto}</span>
                                                <Button variant="outline-primary" onClick={() => agregarPuntoRuta(punto)} style={{ border: 'none', background: 'none', padding: 0 }}>
                                                    <MdKeyboardDoubleArrowRight size={25} style={{ color: 'black' }} />
                                                </Button>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </Col>

                            <Col xs={12} md={6} className="mb-3">
                                <MapaRuta ruta={puntosRuta} setDuraciones={setDuraciones} />
                            </Col>

                            <Col xs={12} md={3} className="mb-3">
                                <Row className="align-items-center" style={{ height: '40px' }}>
                                    <Col xs={3} md={3} className="mb-3">
                                        <h5>Ruta</h5>
                                    </Col>
                                    <Col xs={9} md={9} className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Buscar en ruta..."
                                            value={searchRuta}
                                            onChange={handleSearchRutaChange}
                                        />
                                    </Col>
                                </Row>

                                <ul style={{ padding: 0, margin: 0, height: '350px', overflowY: 'scroll' }}>
                                    {filteredPuntosRuta.map((punto, index) => (
                                        <li key={punto.idPunto} style={{ marginBottom: '8px', listStyle: 'none', padding: 0 }}
                                            draggable
                                            onDragStart={() => handleDragStart(index)}
                                            onDragOver={handleDragOver}
                                            onDrop={() => handleDrop(index)}
                                            onDragEnd={handleDragEnd}
                                        >
                                            <div style={{ backgroundColor: '#f0f0f0', padding: '8px', borderRadius: '5px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <Button variant="outline-primary" onClick={() => eliminarPuntoRuta(index)} style={{ border: 'none', background: 'none', padding: 0 }}>
                                                    <MdKeyboardDoubleArrowLeft size={25} style={{ color: 'black' }} />
                                                </Button>
                                                <span style={{ fontSize: '15px', flex: 1 }}>{punto.punto}</span>
                                                <HiMiniBars3 size={15} />
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </Col>
                        </Row>

                        <hr />

                        <Row>
                            <Col>
                                <TablaDuraciones duraciones={duraciones} />
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button variant="success" onClick={handleSubmit}>
                        Agregar Ruta
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AgregarRuta;
