import React from 'react';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import OtherHousesOutlinedIcon from '@mui/icons-material/OtherHousesOutlined';
import MapIcon from '@mui/icons-material/Map';
import DirectionsIcon from '@mui/icons-material/Directions';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import SportsMotorsportsOutlinedIcon from '@mui/icons-material/SportsMotorsportsOutlined';
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Typography, colors } from '@mui/material';
import { images } from "../../assets";
import Animate from "./Animate";
import { Link } from 'react-router-dom';

const Sidebar = ({ sidebarWidth }) => {
  // Recuperar los datos del usuario del localStorage
  const user = JSON.parse(localStorage.getItem('userData'));
  const userRole = user ? user.rol_idRol : null;  // Manejar la situación en que el usuario no esté disponible
  const activeState = "inicio";

  const MenuItem = (props) => {
    return (
      <ListItem key={props.index} disableGutters disablePadding sx={{ py: 0.5 }}>
        <ListItemButton
          component={Link}
          to={props.item.state}
          sx={{
            borderRadius: "10px",
            bgcolor: props.isActive ? colors.red[600] : "",
            color: props.isActive ? colors.common.white : "",
            "&:hover": {
              bgcolor: props.isActive ? colors.blue[600] : "",
              color: props.isActive ? colors.common.white : "",
            }
          }}>
          <ListItemIcon sx={{
            minWidth: "40px",
            color: props.isActive ? colors.common.white : ""
          }}>
            {props.item.icon}
          </ListItemIcon>
          <ListItemText primary={
            <Typography fontWeight={600}>
              {props.item.title}
            </Typography>
          } />
        </ListItemButton>
      </ListItem>
    );
  };

  const drawer = (
    <Box
      padding={3}
      paddingBottom={0}
      display="flex"
      flexDirection="column"
      height="100vh"
      sx={{
        "::-webkit-scrollbar": {
          display: "none",
          zIndex: 1000
        }
      }}
    >
      {/* Logo */}
      <Box sx={{ textAlign: "center", mb: 2 }}>
        <Animate type="fade" delay={1}>
          <img src={images.logo} alt="logo" height={80} width={200} />
        </Animate>
      </Box>

      <Animate sx={{ flexGrow: 1 }}>
        <Paper
          elevation={0}
          square
          sx={{
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
            p: 2,
            height: "100%",
            boxShadow: "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px"
          }}
        >
          {/* Menus */}
          <List>
            <MenuItem
              key={0}
              item={{ title: "Inicio", icon: <DashboardCustomizeOutlinedIcon />, state: "inicio" }}
              isActive={"inicio" === activeState}
            />
          </List>
          {(userRole === 1 || userRole === 2) && (
            <>
              <List>
                <ListItem>
                  <Typography fontWeight={600} mt={1} color={colors.grey[600]}>
                    Servicios
                  </Typography>
                </ListItem>
                <MenuItem
                  key={1}
                  item={{ title: "Rutas", icon: <DirectionsIcon />, state: "rutas" }}
                  isActive={"rutas" === activeState}
                />
                <MenuItem
                  key={2}
                  item={{ title: "Mapa", icon: <MapIcon />, state: "mapa" }}
                  isActive={"mapa" === activeState}
                />
              </List>
              {userRole === 1 && (
                <List>
                  <ListItem>
                    <Typography fontWeight={600} mt={1} color={colors.grey[600]}>
                      Investments
                    </Typography>
                  </ListItem>
                  <MenuItem
                    key={3}
                    item={{ title: "Usuarios", icon: <ManageAccountsIcon />, state: "usuarios" }}
                    isActive={"usuarios" === activeState}
                  />
                  <MenuItem
                    key={4}
                    item={{ title: "Destinos", icon: <EditLocationIcon />, state: "destinos" }}
                    isActive={"destinos" === activeState}
                  />
                  <MenuItem
                    key={5}
                    item={{ title: "Configuración", icon: <SettingsApplicationsIcon />, state: "configuracion" }}
                    isActive={"configuracion" === activeState}
                  />
                </List>
              )}
            </>
          )}
        </Paper>
      </Animate>
    </Box>
  );

  return (
    <Box
      component="nav"
      sx={{
        width: { md: sidebarWidth },
        flexShrink: { md: 0 }
      }}
    >
      {/* Large screen */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "none", md: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: sidebarWidth,
            borderWidth: 0,
            bgcolor: "transparent",
            "::-webkit-scrollbar": {
              display: "none"
            },
            zIndex: 1000
          }
        }}
        open
      >
        {drawer}
      </Drawer>
      {/* Large screen */}
    </Box>
  );
};

export default Sidebar;
