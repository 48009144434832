import React, { useState, useEffect } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from 'react-bootstrap';
import AgregarRuta from "./AgregarRuta";
import { CgPushChevronDown, CgPushChevronUp } from 'react-icons/cg';

const TablaRuta = () => {
    const [showModal, setShowModal] = useState(false);
    const [rutas, setRutas] = useState([]);
    const [selectedRuta, setSelectedRuta] = useState(null);

    const handleCloseModal = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const togglePuntos = (rutaId) => {
        setSelectedRuta(selectedRuta === rutaId ? null : rutaId);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://cronos.edgarleal.es/token/api/web/Rutas/TablaRutas.php');
                const data = await response.json();
                setRutas(data);
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };
        fetchData();
    }, []);

    return (
        <div className="container">
            <div className="crud shadow-lg p-3 mb-5 mt-5 bg-body rounded">
                <div className="row">
                    <div className="col-sm-3 mt-5 mb-4 text-gred">
                        <div className="search">
                            <form className="form-inline">
                                <input className="form-control mr-sm-2" type="search" placeholder="Buscar" aria-label="Search" />
                            </form>
                        </div>
                    </div>
                    <div className="col-sm-3 offset-sm-2 mt-5 mb-4 text-gred" style={{ color: "red" }}>
                        <h2><b>Rutas</b></h2>
                    </div>
                    <div className="col-sm-3 offset-sm-1  mt-5 mb-4 text-gred">
                        <AgregarRuta />
                    </div>
                </div>
                <div className="row">
                    <div className="table-responsive">
                        <table className="table table-striped table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th style={{ width: "5px" }}></th>
                                    <th>N°</th>
                                    <th>Chofer</th>
                                    <th>Puntos</th>
                                    <th>Fecha</th>
                                    <th>Estado</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rutas.map((ruta) => (
                                    <React.Fragment key={ruta.idRuta}>
                                        <tr>
                                            <td >
                                                <Button variant="link" onClick={() => togglePuntos(ruta.idRuta)}>
                                                    {selectedRuta === ruta.idRuta ?
                                                        <CgPushChevronUp style={{ color: "black", fontSize: "25px" }} /> :
                                                        <CgPushChevronDown style={{ color: "black", fontSize: "25px" }} />}
                                                </Button>
                                            </td>
                                            <td>{ruta.idRuta}</td>
                                            <td>{ruta.chofer}</td>
                                            <td>{ruta.total_puntos}</td> {/* Mostrar el conteo de puntos */}
                                            <td>{ruta.fecharuta}</td>
                                            <td>{ruta.estado}</td>
                                        </tr>
                                        {selectedRuta === ruta.idRuta && ruta.detalles_puntos && /* Verificar si detalles_puntos existe */
                                            <tr>
                                                <td colSpan="6">
                                                    <div>
                                                        <table className="table table-striped table-hover table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>Orden</th> {/* Cambiar el encabezado a "Orden" */}
                                                                    <th>Punto</th>
                                                                    <th>Dirección</th>
                                                                    <th>Contacto</th>
                                                                    <th>Teléfono</th>
                                                                    <th>Estado</th> {/* Mantener la columna de "Estado" */}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {ruta.detalles_puntos.map((punto, index) => ( /* Utilizar el índice para mostrar el orden */
                                                                    <tr key={punto.idPunto}>
                                                                        <td>{index + 1}</td> {/* Mostrar el orden del punto */}
                                                                        <td>{punto.punto}</td>
                                                                        <td>{punto.direccion}</td>
                                                                        <td>{punto.contacto}</td>
                                                                        <td>{punto.telefono}</td>
                                                                        <td>{punto.estado}</td> {/* Mostrar el estado del punto */}
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TablaRuta;
