import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { Card } from 'react-bootstrap';
import CardUsuarios from '../components/mapa/CardUsuarios';
import MostrarMapa from '../components/mapa/MostrarMapa'; // Importa tu componente MostrarMapa

const defaultCenter = { lat: -33.4489, lng: -70.6693 }; // Centro por defecto del mapa

const MapaPage = () => {
    const [usuarios, setUsuarios] = useState([]);
    const [marcadores, setMarcadores] = useState([]);
    const [rutas, setRutas] = useState([]);

    useEffect(() => {
        setMarcadores(
            usuarios.map(usuario => ({
                id: usuario.id,
                position: { lat: parseFloat(usuario.latitude), lng: parseFloat(usuario.longitude) },
                title: `Usuario: ${usuario.usuario}`,
                photoURL: usuario.fotoperfil
            }))
        );
    }, [usuarios]);

    return (
        <Box display="flex" height="100%">
            <Box flex="3"> {/* Puedes ajustar el tamaño del mapa */}
                <MostrarMapa /> {/* Renderiza el mapa solo una vez */}
            </Box>
            <Box flex="1" style={{ overflowY: 'auto', padding: '10px' }}>
                {usuarios.map(usuario => (
                    <CardUsuarios key={usuario.id} usuario={usuario} />
                ))}
            </Box>
            
        </Box>
    );
};

export default MapaPage;
