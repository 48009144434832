export const images = {
  loginBg: require("./images/3d-render-tax-payment-financial-business-concept.jpg"),
  summaryImages: {
    UsuariosTotales: require("./images/dashboardusuarios.png"),
    DestinosTotales: require("./images/dashboarddestinos.png"),
    cancel: require("./images/perspective_matte-15-128x128.png"),
    FotoNuevoUsuario: require("./images/fotoperfil.png")
  },
  userProfile: require("./images/7309681.jpg"),
  bookingImage: require("./images/cherry-blossoms-fuji-mountain-spring-sunrise-shizuoka-japan.jpg"),
  logo: require("./images/5bv3.png")
};