import React, { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

const GOOGLE_MAPS_API_KEY = 'AIzaSyCxK9DUpBomKCM1gkSuAmiF3I0bu2WWm5k';

function BuscadorDirecciones({ onImageSelect, onDireccionSelect, onSelectPlace }) {
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [mapCenter, setMapCenter] = useState({ lat: -33.4372, lng: -70.6506 });
    const [mapZoom, setMapZoom] = useState(10);
    const [markers, setMarkers] = useState([]);
    const autocompleteTimeout = useRef(null);

    useEffect(() => {
        if (inputValue.length >= 3) {
            if (autocompleteTimeout.current) {
                clearTimeout(autocompleteTimeout.current);
            }
            autocompleteTimeout.current = setTimeout(() => {
                searchPlaces();
            }, 2000); // Delay de 2 segundos
        }
    }, [inputValue]);

    const searchPlaces = () => {
        const autocompleteService = new window.google.maps.places.AutocompleteService();
        autocompleteService.getPlacePredictions(
            {
                input: inputValue,
                language: 'es',
                types: ['geocode', 'establishment'],
                componentRestrictions: { country: 'cl' },
            },
            (predictions, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
                    setSuggestions(predictions);
                } else {
                    setSuggestions([]);
                }
            }
        );
    };

    const handleSuggestionSelect = (event, value) => {
        if (value) {
            setInputValue(value.description);
            const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
            placesService.getDetails({ placeId: value.place_id, language: 'es' }, (place, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    setSelectedPlace(place);
                    setMapCenter({
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng(),
                    });
                    setMapZoom(15);
                    setMarkers([{ position: place.geometry.location }]);
                    onDireccionSelect(place.formatted_address);

                    if (place.photos && place.photos.length > 0) {
                        const images = place.photos.map(photo => photo.getUrl({ maxWidth: 400, maxHeight: 300 }));
                        onImageSelect(images);
                    } else {
                        const streetViewImage = `https://maps.googleapis.com/maps/api/streetview?size=400x300&location=${place.geometry.location.lat()},${place.geometry.location.lng()}&key=${GOOGLE_MAPS_API_KEY}`;
                        onImageSelect([streetViewImage]);
                    }

                    // Llama a la función prop proporcionada para pasar selectedPlace a AgregarDestino
                    onSelectPlace({
                        geometry: {
                            location: {
                                lat: () => place.geometry.location.lat(),
                                lng: () => place.geometry.location.lng(),
                            }
                        },
                        formatted_address: place.formatted_address,
                        name: place.name,
                    });
                }
            });
        }
    };


    const handleSearchClick = () => {
        searchPlaces();
    };

    const handleMarkerDragEnd = (event) => {
        const newLat = event.latLng.lat();
        const newLng = event.latLng.lng();
        setMarkers([{ position: { lat: newLat, lng: newLng } }]);
        setMapCenter({ lat: newLat, lng: newLng });

        // Fetch Street View image based on new marker position
        const streetViewImage = `https://maps.googleapis.com/maps/api/streetview?size=400x300&location=${newLat},${newLng}&key=${GOOGLE_MAPS_API_KEY}`;
        onImageSelect([streetViewImage]);
    };

    return (
        <div style={{ position: 'relative' }}>
            <Autocomplete
                freeSolo
                options={suggestions}
                getOptionLabel={(option) => option.description}
                onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                onChange={handleSuggestionSelect}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Buscar dirección o lugar"
                        variant="outlined"
                        fullWidth
                        placeholder="Escribe una dirección, lugar, tienda, etc."
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleSearchClick}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
                renderOption={(props, option) => (
                    <li {...props} key={option.place_id}>
                        {option.description}
                    </li>
                )}
            />
            <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={['places']} language="es">
                <GoogleMap
                    id="map"
                    mapContainerStyle={{ width: '100%', height: '400px', marginTop: '20px' }}
                    center={mapCenter}
                    zoom={mapZoom}
                >
                    {markers.map((marker, index) => (
                        <Marker
                            key={index}
                            position={marker.position}
                            draggable={true}
                            onDragEnd={handleMarkerDragEnd}
                        />
                    ))}
                </GoogleMap>
            </LoadScript>
        </div>
    );
}

export default BuscadorDirecciones;
